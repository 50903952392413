/*
	Init data for "Adobe Experience Manager".
	We create a custom dataLayer (named digitalData), that we apply our site information to, this enables better tracking.
	This need to be accessible as a global variable.

	https://marketing.adobe.com/resources/help/en_US/dtm/c_custom-data-layer-example.html

*/
function analyticsInit(params) {
	const cookieType = { CAMPAIGN: 1, PAGE: 2, WEBSITE: 3 };

	window.adobeDataLayer = window.adobeDataLayer || [];

	function getStacking() {
		const languagePath = /^:[a-z]{2}-[a-z]{2}/;
		let path = window.location.pathname.toLowerCase().replace(/\//g, ':');

		if (!path.match(languagePath)) {
			switch (window.location.host) {
				case 'cn.saxobank.com':
					path = 'zh-cn' + path;
					break;
				case 'www.bgsaxo.it':
				case 'bgsaxo.it':
					path = 'it-it' + path;
					break;
				default:
					path = 'en' + path;
			}
		}

		return path.replace(/^:/, '').replace(/:$/, '');
	}

	function getCookie(name) {
		const value = document.cookie
			.split(';')
			.filter(cookie => cookie.match(new RegExp(name + '=.*')))[0];

		return value ? value.split('=')[1] : '';
	}

	function getQueryString(name) {
		const match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
		return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
	}

	function stackCookie(data, cookie, crumbsDepth) {
		let array = [];
		let previousCookie = '';
		let type = '';
		let value;
		const crumbs = getStacking();

		switch (cookie) {
			default:
			case cookieType.PAGE:
				type = data.prevCookies.page;
				value = crumbs;
				break;
			case cookieType.CAMPAIGN:
				type = data.prevCookies.campaigns;
				value = getQueryString('cmpid');
				break;
			case cookieType.WEBSITE:
				type = data.prevCookies.sites;
				value = data.website;
				break;
		}

		previousCookie = type && getCookie(type);

		if (!value) {
			return previousCookie;
		}

		if (previousCookie) {
			array = previousCookie.split('>');
		}

		if (value && value !== array[array.length - 1]) {
			if (array.length === crumbsDepth) {
				array.shift();
			}

			array.push(value);

			if (type) {
				document.cookie = type + '=' + array.join('>') + '; path=/';
			}
		}

		return array.join('>');
	}
	const crumbs = getStacking();
	const sections = crumbs.split(':');

	const pageLoad = {
		pageInfo: {
			server: window.location.hostname,
			fullUrl: window.location.href,
			fullQuery: window.location.search,
			language: params.language,
			pageName: crumbs,
			campaignStacking: stackCookie(params, cookieType.CAMPAIGN, 5),
			websiteStacking: stackCookie(params, cookieType.WEBSITE, 3),
			pageStacking: stackCookie(params, cookieType.PAGE, 5),
			siteSection1: sections[0] || '',
			siteSection2: sections[1] || '',
			siteSection3: sections[2] || '',
			siteSection4: sections[3] || '',
			siteSection5: sections[4] || '',
			website: params.website,
			pageCode: params.responseStatusCode,
			pageType: params.responseStatusCode === 404 ? 'errorPage' : ''
		}
	};
	// TODO : need to check where to add mPulse and consentInfo details in the object

	window.adobeDataLayer.push({ event: 'web.webpagedetails.pageViews', ...pageLoad });
	// push the page load info
}

if (window.analyticsConfig) {
	analyticsInit(window.analyticsConfig);
}

if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
	module.exports = {
		analyticsInit
	};
}
